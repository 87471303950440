var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SwContainer',_vm._b({},'SwContainer',{
        stretched: _vm.config.full_height,
        paddingY: _vm.config.block_padding_y,
        bgColor: _vm.config.type_background === 'color'
            ? _vm.config.background_color
            : _vm.config.background_image_color,
        bgImage: _vm.config.background_url_image,
        bgOpacity: _vm.config.type_background === 'color'
            ? _vm.config.background_opacity
            : _vm.config.background_image_opacity,
    },false),[(_vm.styleKey === _vm.VARIANTS.TEXT_AND_IMAGE)?_c('TextAndImage',{attrs:{"title":_vm.config.heading_text,"text":_vm.config.text_text,"image":_vm.config.one_photo_image,"position":_vm.position,"inverted":_vm.config.inverted_font_color}}):(_vm.styleKey === _vm.VARIANTS.TEXT_ON_IMAGE)?_c('TextOnImage',{attrs:{"title":_vm.config.heading_text,"text":_vm.config.text_text,"position":_vm.position}}):_c('ImageGallery',{attrs:{"images":_vm.config.photo_images}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }