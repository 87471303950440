<template>
    <SwContainer
        v-bind="{
            stretched: config.full_height,
            paddingY: config.block_padding_y,
            bgColor: config.type_background === 'color'
                ? config.background_color
                : config.background_image_color,
            bgImage: config.background_url_image,
            bgOpacity: config.type_background === 'color'
                ? config.background_opacity
                : config.background_image_opacity,
        }"
    >
        <TextAndImage
            v-if="styleKey === VARIANTS.TEXT_AND_IMAGE"
            :title="config.heading_text"
            :text="config.text_text"
            :image="config.one_photo_image"
            :position="position"
            :inverted="config.inverted_font_color"
        />

        <TextOnImage
            v-else-if="styleKey === VARIANTS.TEXT_ON_IMAGE"
            :title="config.heading_text"
            :text="config.text_text"
            :position="position"
        />

        <ImageGallery
            v-else
            :images="config.photo_images"
        />
    </SwContainer>
</template>

<script>
const VARIANTS = {
  IMAGE_GALLERY: 'gallery',
  TEXT_AND_IMAGE: 'photo_with_caption',
  TEXT_ON_IMAGE: 'caption_with_background'
}

export default {
  name: 'BlockMedia',

  components: {
    TextAndImage: () => import('@@/website/components/blocks/BlockMedia/TextAndImage'),
    TextOnImage: () => import('@@/website/components/blocks/BlockMedia/TextOnImage'),
    ImageGallery: () => import('@@/website/components/blocks/BlockMedia/ImageGallery')
  },

  props: {
    styleKey: {
      type: String,
      default: ''
    },

    config: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    VARIANTS: () => VARIANTS,

    position () {
      const value = this.config.content_location_for_photo_with_caption
        ? this.config.content_location_for_photo_with_caption
        : this.config.content_location_for_caption_with_background

      switch (value) {
        case 'photo_with_caption_right':
        case 'caption_with_background_right':
          return 'right'
        case 'photo_with_caption_center':
        case 'caption_with_background_center':
          return 'center'
        default:
          return 'left'
      }
    }
  }
}
</script>
